import React from "react";
import { Helmet } from "react-helmet";
import CustomTabs from "../../components/CustomTabs";
import Layout from "../../components/layout";
import * as baseStyles from "../index.module.css";
import * as styles from "./styles.module.css";

// images
import poynt5 from "../../images/poynt-5-smart-terminal.png";
import poynt3303 from "../../images/poynt-3303.png";
import a920_mobile_tablet from "../../images/a920.png";
import a80 from "../../images/a80.webp";
import n910_mobile_android_pos from "../../images/n910_mobile_android_pos.png";

export default function SmartTerminals() {
  return (
    <Layout>
      <Helmet>
        <title>Smart terminals | MyEZSolutions</title>
      </Helmet>

      <div className={baseStyles.pageContent}>
        <div className={baseStyles.pageTitle}>
          <h1>Smart Terminals</h1>
          <p>
            Completely customizable to meet your unique needs, these
            game-changing devices offer <br />
            everything from basic functions to more technical and specialized
            tools for running your business
          </p>
        </div>

        <div className={baseStyles.pageContentInner}>
          <div className={styles.productsContainer}>
            <CustomTabs
              tabs={[
                {
                  title: "Poynt 5",
                  img: poynt5,
                  specs: [
                    [
                      "Processor",
                      <>
                        Application processor: 4-core 1.3 GHz ARM Cortex A53
                        <br />
                        Security processor: 108MHz ARM Cortex M3
                      </>,
                    ],
                    ["Memory", "1GB DDR RAM"],
                    ["Storage", "8GB Flash"],
                    ["Operating System", "Poynt OS"],
                    ["WiFi", "802.11 a/b/g/n"],
                    [
                      "Camera",
                      "Rear camera: 5 MP with flash and red targeting LED",
                    ],
                    [
                      "Card reader",
                      <>
                        <b>Hybrid MSR/Chip Reader</b>
                        <br />
                        Magnetic stripe reader: Triple track (tracks 1, 2, 3),
                        high coercivity, bi-directional
                        <br />
                        Chip reader: ISO 7816, 1.8V, 3V, 5V; synchronous and
                        synchronous cards; EMV Level 1 and 2 Type approved
                      </>,
                    ],
                    [
                      "Audio/microphone",
                      <>
                        Mono speaker
                        <br />
                        Built-in microphone
                      </>,
                    ],
                    ["Physical", "76.3 mm x 154.0 mm x 25.4 mm | 201.6g"],
                    [
                      "Protocols",
                      <>
                        Supports major NFC/contactless schemes
                        <br />
                        ISO 14442 A&B, MiFare capable, ISO18092 capable
                      </>,
                    ],
                    [
                      "Packed applications",
                      "Poynt Terminal, Poynt Register, Settlement, Help",
                    ],
                    [
                      "Other features",
                      <>
                        3-axis accelerometer
                        <br />
                        Switchable full-scale ranges (≥ 5 ranges)
                      </>,
                    ],
                    [
                      "Security",
                      <>
                        PCI PTS PED and SRED 4.x, End to end encryption
                        <br />
                        Supports TLS 1.2
                      </>,
                    ],
                    [
                      "Environmental",
                      <>
                        Operation 0º to 50ºC
                        <br />
                        90% Maximum non-condensing up to 45ªC
                        <br />
                        Storage: -20ºC to 75ºC
                        <br />
                        90% Maximum non-condensing up to 60ºC
                      </>,
                    ],
                    [
                      "Certification",
                      "EMVCo L1 and L2 certification, PTCRB NFCC",
                    ],
                    [
                      "EMV ready",
                      "Device is EMV ready for standarf PCI PTS 4.x",
                    ],
                    [
                      "Smart card reader life",
                      "Smart Card: 500,000 reads. MSR: 250,000 swipes",
                    ],
                    [
                      "Power",
                      "10 W, 5 V, 2 A (100~240V) | Battery: 2Ah, 4.35 V, 800 cycles",
                    ],
                  ],
                  features: [
                    {
                      icon: (
                        <svg
                          style={{ width: 48, height: 48 }}
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M22,21H2V3H4V19H6V10H10V19H12V6H16V19H18V14H22V21Z"
                          />
                        </svg>
                      ),
                      title: "Powerful Reporting Dashboard",
                      description:
                        "Get real-time access to transactions, trends and customer information, and make data-informed decisions at a moment’s notice. Keep close tabs on finances, set up new users and run customized reports from your home, at the office or on the go. ",
                    },
                    {
                      icon: (
                        <svg
                          style={{ width: 48, height: 48 }}
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M9,20A2,2 0 0,1 7,22A2,2 0 0,1 5,20A2,2 0 0,1 7,18A2,2 0 0,1 9,20M17,18A2,2 0 0,0 15,20A2,2 0 0,0 17,22A2,2 0 0,0 19,20A2,2 0 0,0 17,18M7.2,14.63C7.19,14.67 7.19,14.71 7.2,14.75A0.25,0.25 0 0,0 7.45,15H19V17H7A2,2 0 0,1 5,15C5,14.65 5.07,14.31 5.24,14L6.6,11.59L3,4H1V2H4.27L5.21,4H20A1,1 0 0,1 21,5C21,5.17 20.95,5.34 20.88,5.5L17.3,12C16.94,12.62 16.27,13 15.55,13H8.1L7.2,14.63M9,9.5H13V11.5L16,8.5L13,5.5V7.5H9V9.5Z"
                          />
                        </svg>
                      ),
                      title: "Fast Transactions",
                      description:
                        "Keep payments simple for both customer and employee. Every transaction automatically applies taxes, surcharges and discounts, creating a streamlined experience that reduces errors and keeps the line moving. ",
                    },
                    {
                      icon: (
                        <svg
                          style={{ width: 48, height: 48 }}
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M20.07,4.93C21.88,6.74 23,9.24 23,12C23,14.76 21.88,17.26 20.07,19.07L18.66,17.66C20.11,16.22 21,14.22 21,12C21,9.79 20.11,7.78 18.66,6.34L20.07,4.93M17.24,7.76C18.33,8.85 19,10.35 19,12C19,13.65 18.33,15.15 17.24,16.24L15.83,14.83C16.55,14.11 17,13.11 17,12C17,10.89 16.55,9.89 15.83,9.17L17.24,7.76M13,10A2,2 0 0,1 15,12A2,2 0 0,1 13,14A2,2 0 0,1 11,12A2,2 0 0,1 13,10M11.5,1A2.5,2.5 0 0,1 14,3.5V8H12V4H3V19H12V16H14V20.5A2.5,2.5 0 0,1 11.5,23H3.5A2.5,2.5 0 0,1 1,20.5V3.5A2.5,2.5 0 0,1 3.5,1H11.5Z"
                          />
                        </svg>
                      ),
                      title: "Mobility",
                      description:
                        "Take payments in your store or at an industry trade show or local farmer’s market. Any member of your team can become a checkout cashier, and you can check sales, stats and inventory in an instant ",
                    },
                    {
                      icon: (
                        <svg
                          style={{ width: 48, height: 48 }}
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M3,6H21V18H3V6M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9M7,8A2,2 0 0,1 5,10V14A2,2 0 0,1 7,16H17A2,2 0 0,1 19,14V10A2,2 0 0,1 17,8H7Z"
                          />
                        </svg>
                      ),
                      title: "Affordability",
                      description:
                        "Pay one-tenth of the price of traditional payment platforms",
                    },
                    {
                      icon: (
                        <svg
                          style={{ width: 48, height: 48 }}
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M21,18H24V20H21V23H19V20H16V18H19V15H21V18M19,8V6H3V8H19M19,12H3V18H14V20H3C1.89,20 1,19.1 1,18V6C1,4.89 1.89,4 3,4H19A2,2 0 0,1 21,6V13H19V12Z"
                          />
                        </svg>
                      ),
                      title: "Multiple Payment Options",
                      description:
                        "Accept any payment method, be it cash, card or contactless. The system is primed to embrace change and will easily incorporate new payment methods. The days of “I’m sorry, we don’t accept that” are over ",
                    },
                    {
                      icon: (
                        <svg
                          style={{ width: 48, height: 48 }}
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M21.41,11.58L12.41,2.58C12.05,2.22 11.55,2 11,2H4A2,2 0 0,0 2,4V11C2,11.55 2.22,12.05 2.59,12.42L11.59,21.42C11.95,21.78 12.45,22 13,22C13.55,22 14.05,21.78 14.41,21.41L21.41,14.41C21.78,14.05 22,13.55 22,13C22,12.45 21.77,11.94 21.41,11.58M5.5,7A1.5,1.5 0 0,1 4,5.5A1.5,1.5 0 0,1 5.5,4A1.5,1.5 0 0,1 7,5.5A1.5,1.5 0 0,1 5.5,7M17.27,15.27L13,19.54L8.73,15.27C8.28,14.81 8,14.19 8,13.5A2.5,2.5 0 0,1 10.5,11C11.19,11 11.82,11.28 12.27,11.74L13,12.46L13.73,11.73C14.18,11.28 14.81,11 15.5,11A2.5,2.5 0 0,1 18,13.5C18,14.19 17.72,14.82 17.27,15.27Z"
                          />
                        </svg>
                      ),
                      title: "Ease of Use",
                      description:
                        "Be up and running in as little as five minutes with an intuitive user interface and step-by-step tutorials. ",
                    },
                    {
                      icon: (
                        <svg
                          style={{ width: 48, height: 48 }}
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M12,12H19C18.47,16.11 15.72,19.78 12,20.92V12H5V6.3L12,3.19M12,1L3,5V11C3,16.55 6.84,21.73 12,23C17.16,21.73 21,16.55 21,11V5L12,1Z"
                          />
                        </svg>
                      ),
                      title: "Reliable Security",
                      description:
                        "Rest easy with fully protected and PCI-compliant payment data and customer information ",
                    },
                    {
                      icon: (
                        <svg
                          style={{ width: 48, height: 48 }}
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M18.72,14.76C19.07,13.91 19.26,13 19.26,12C19.26,11.28 19.15,10.59 18.96,9.95C18.31,10.1 17.63,10.18 16.92,10.18C13.86,10.18 11.15,8.67 9.5,6.34C8.61,8.5 6.91,10.26 4.77,11.22C4.73,11.47 4.73,11.74 4.73,12A7.27,7.27 0 0,0 12,19.27C13.05,19.27 14.06,19.04 14.97,18.63C15.54,19.72 15.8,20.26 15.78,20.26C14.14,20.81 12.87,21.08 12,21.08C9.58,21.08 7.27,20.13 5.57,18.42C4.53,17.38 3.76,16.11 3.33,14.73H2V10.18H3.09C3.93,6.04 7.6,2.92 12,2.92C14.4,2.92 16.71,3.87 18.42,5.58C19.69,6.84 20.54,8.45 20.89,10.18H22V14.67H22V14.69L22,14.73H21.94L18.38,18L13.08,17.4V15.73H17.91L18.72,14.76M9.27,11.77C9.57,11.77 9.86,11.89 10.07,12.11C10.28,12.32 10.4,12.61 10.4,12.91C10.4,13.21 10.28,13.5 10.07,13.71C9.86,13.92 9.57,14.04 9.27,14.04C8.64,14.04 8.13,13.54 8.13,12.91C8.13,12.28 8.64,11.77 9.27,11.77M14.72,11.77C15.35,11.77 15.85,12.28 15.85,12.91C15.85,13.54 15.35,14.04 14.72,14.04C14.09,14.04 13.58,13.54 13.58,12.91A1.14,1.14 0 0,1 14.72,11.77Z"
                          />
                        </svg>
                      ),
                      title: "24/7 Support",
                      description:
                        "Reach out any time to get answers or troubleshoot issues. You don’t clock out at 5pm, and neither do we. ",
                    },
                  ],
                },
                {
                  title: "Poynt 3303",
                  img: poynt3303,
                  description: "",
                  specs: [
                    [
                      "Processor",
                      <>
                        Application processor: 4-core 1.3 GHz ARM Cortex A53
                        <br />
                        Security processor: 108MHz ARM Cortex M3
                      </>,
                    ],
                    ["Memory", "1GB DDR RAM"],
                    ["Storage", "8GB Flash"],
                    ["Operating System", "Poynt OS"],
                    ["WiFi", "802.11 a/b/g/n"],
                    [
                      "Camera",
                      "Rear camera: 5 MP with flash and red targeting LED",
                    ],
                    [
                      "Card reader",
                      <>
                        <b>Hybrid MSR/Chip Reader</b>
                        <br />
                        Magnetic stripe reader: Triple track (tracks 1, 2, 3),
                        high coercivity, bi-directional
                        <br />
                        Chip reader: ISO 7816, 1.8V, 3V, 5V; synchronous and
                        synchronous cards; EMV Level 1 and 2 Type approved
                      </>,
                    ],
                    [
                      "Audio/microphone",
                      <>
                        Mono speaker
                        <br />
                        Built-in microphone
                      </>,
                    ],
                    ["Physical", "76.3 mm x 154.0 mm x 25.4 mm | 201.6g"],
                    [
                      "Protocols",
                      <>
                        Supports major NFC/contactless schemes
                        <br />
                        ISO 14442 A&B, MiFare capable, ISO18092 capable
                      </>,
                    ],
                    [
                      "Packed applications",
                      "Poynt Terminal, Poynt Register, Settlement, Help",
                    ],
                    [
                      "Other features",
                      <>
                        3-axis accelerometer
                        <br />
                        Switchable full-scale ranges (≥ 5 ranges)
                      </>,
                    ],
                    [
                      "Security",
                      <>
                        PCI PTS PED and SRED 4.x, End to end encryption
                        <br />
                        Supports TLS 1.2
                      </>,
                    ],
                    [
                      "Environmental",
                      <>
                        Operation 0º to 50ºC
                        <br />
                        90% Maximum non-condensing up to 45ªC
                        <br />
                        Storage: -20ºC to 75ºC
                        <br />
                        90% Maximum non-condensing up to 60ºC
                      </>,
                    ],
                    [
                      "Certification",
                      "EMVCo L1 and L2 certification, PTCRB NFCC",
                    ],
                    [
                      "EMV ready",
                      "Device is EMV ready for standarf PCI PTS 4.x",
                    ],
                    [
                      "Smart card reader life",
                      "Smart Card: 500,000 reads. MSR: 250,000 swipes",
                    ],
                    [
                      "Power",
                      "10 W, 5 V, 2 A (100~240V) | Battery: 2Ah, 4.35 V, 800 cycles",
                    ],
                  ],
                  features: [
                    {
                      icon: (
                        <svg
                          style={{ width: 48, height: 48 }}
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M22,21H2V3H4V19H6V10H10V19H12V6H16V19H18V14H22V21Z"
                          />
                        </svg>
                      ),
                      title: "Powerful Reporting Dashboard",
                      description:
                        "Get real-time access to transactions, trends and customer information, and make data-informed decisions at a moment’s notice. Keep close tabs on finances, set up new users and run customized reports from your home, at the office or on the go. ",
                    },
                    {
                      icon: (
                        <svg
                          style={{ width: 48, height: 48 }}
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M9,20A2,2 0 0,1 7,22A2,2 0 0,1 5,20A2,2 0 0,1 7,18A2,2 0 0,1 9,20M17,18A2,2 0 0,0 15,20A2,2 0 0,0 17,22A2,2 0 0,0 19,20A2,2 0 0,0 17,18M7.2,14.63C7.19,14.67 7.19,14.71 7.2,14.75A0.25,0.25 0 0,0 7.45,15H19V17H7A2,2 0 0,1 5,15C5,14.65 5.07,14.31 5.24,14L6.6,11.59L3,4H1V2H4.27L5.21,4H20A1,1 0 0,1 21,5C21,5.17 20.95,5.34 20.88,5.5L17.3,12C16.94,12.62 16.27,13 15.55,13H8.1L7.2,14.63M9,9.5H13V11.5L16,8.5L13,5.5V7.5H9V9.5Z"
                          />
                        </svg>
                      ),
                      title: "Fast Transactions",
                      description:
                        "Keep payments simple for both customer and employee. Every transaction automatically applies taxes, surcharges and discounts, creating a streamlined experience that reduces errors and keeps the line moving. ",
                    },
                    {
                      icon: (
                        <svg
                          style={{ width: 48, height: 48 }}
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M20.07,4.93C21.88,6.74 23,9.24 23,12C23,14.76 21.88,17.26 20.07,19.07L18.66,17.66C20.11,16.22 21,14.22 21,12C21,9.79 20.11,7.78 18.66,6.34L20.07,4.93M17.24,7.76C18.33,8.85 19,10.35 19,12C19,13.65 18.33,15.15 17.24,16.24L15.83,14.83C16.55,14.11 17,13.11 17,12C17,10.89 16.55,9.89 15.83,9.17L17.24,7.76M13,10A2,2 0 0,1 15,12A2,2 0 0,1 13,14A2,2 0 0,1 11,12A2,2 0 0,1 13,10M11.5,1A2.5,2.5 0 0,1 14,3.5V8H12V4H3V19H12V16H14V20.5A2.5,2.5 0 0,1 11.5,23H3.5A2.5,2.5 0 0,1 1,20.5V3.5A2.5,2.5 0 0,1 3.5,1H11.5Z"
                          />
                        </svg>
                      ),
                      title: "Mobility",
                      description:
                        "Take payments in your store or at an industry trade show or local farmer’s market. Any member of your team can become a checkout cashier, and you can check sales, stats and inventory in an instant ",
                    },
                    {
                      icon: (
                        <svg
                          style={{ width: 48, height: 48 }}
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M3,6H21V18H3V6M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9M7,8A2,2 0 0,1 5,10V14A2,2 0 0,1 7,16H17A2,2 0 0,1 19,14V10A2,2 0 0,1 17,8H7Z"
                          />
                        </svg>
                      ),
                      title: "Affordability",
                      description:
                        "Pay one-tenth of the price of traditional payment platforms",
                    },
                    {
                      icon: (
                        <svg
                          style={{ width: 48, height: 48 }}
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M21,18H24V20H21V23H19V20H16V18H19V15H21V18M19,8V6H3V8H19M19,12H3V18H14V20H3C1.89,20 1,19.1 1,18V6C1,4.89 1.89,4 3,4H19A2,2 0 0,1 21,6V13H19V12Z"
                          />
                        </svg>
                      ),
                      title: "Multiple Payment Options",
                      description:
                        "Accept any payment method, be it cash, card or contactless. The system is primed to embrace change and will easily incorporate new payment methods. The days of “I’m sorry, we don’t accept that” are over ",
                    },
                    {
                      icon: (
                        <svg
                          style={{ width: 48, height: 48 }}
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M21.41,11.58L12.41,2.58C12.05,2.22 11.55,2 11,2H4A2,2 0 0,0 2,4V11C2,11.55 2.22,12.05 2.59,12.42L11.59,21.42C11.95,21.78 12.45,22 13,22C13.55,22 14.05,21.78 14.41,21.41L21.41,14.41C21.78,14.05 22,13.55 22,13C22,12.45 21.77,11.94 21.41,11.58M5.5,7A1.5,1.5 0 0,1 4,5.5A1.5,1.5 0 0,1 5.5,4A1.5,1.5 0 0,1 7,5.5A1.5,1.5 0 0,1 5.5,7M17.27,15.27L13,19.54L8.73,15.27C8.28,14.81 8,14.19 8,13.5A2.5,2.5 0 0,1 10.5,11C11.19,11 11.82,11.28 12.27,11.74L13,12.46L13.73,11.73C14.18,11.28 14.81,11 15.5,11A2.5,2.5 0 0,1 18,13.5C18,14.19 17.72,14.82 17.27,15.27Z"
                          />
                        </svg>
                      ),
                      title: "Ease of Use",
                      description:
                        "Be up and running in as little as five minutes with an intuitive user interface and step-by-step tutorials. ",
                    },
                    {
                      icon: (
                        <svg
                          style={{ width: 48, height: 48 }}
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M12,12H19C18.47,16.11 15.72,19.78 12,20.92V12H5V6.3L12,3.19M12,1L3,5V11C3,16.55 6.84,21.73 12,23C17.16,21.73 21,16.55 21,11V5L12,1Z"
                          />
                        </svg>
                      ),
                      title: "Reliable Security",
                      description:
                        "Rest easy with fully protected and PCI-compliant payment data and customer information ",
                    },
                    {
                      icon: (
                        <svg
                          style={{ width: 48, height: 48 }}
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M18.72,14.76C19.07,13.91 19.26,13 19.26,12C19.26,11.28 19.15,10.59 18.96,9.95C18.31,10.1 17.63,10.18 16.92,10.18C13.86,10.18 11.15,8.67 9.5,6.34C8.61,8.5 6.91,10.26 4.77,11.22C4.73,11.47 4.73,11.74 4.73,12A7.27,7.27 0 0,0 12,19.27C13.05,19.27 14.06,19.04 14.97,18.63C15.54,19.72 15.8,20.26 15.78,20.26C14.14,20.81 12.87,21.08 12,21.08C9.58,21.08 7.27,20.13 5.57,18.42C4.53,17.38 3.76,16.11 3.33,14.73H2V10.18H3.09C3.93,6.04 7.6,2.92 12,2.92C14.4,2.92 16.71,3.87 18.42,5.58C19.69,6.84 20.54,8.45 20.89,10.18H22V14.67H22V14.69L22,14.73H21.94L18.38,18L13.08,17.4V15.73H17.91L18.72,14.76M9.27,11.77C9.57,11.77 9.86,11.89 10.07,12.11C10.28,12.32 10.4,12.61 10.4,12.91C10.4,13.21 10.28,13.5 10.07,13.71C9.86,13.92 9.57,14.04 9.27,14.04C8.64,14.04 8.13,13.54 8.13,12.91C8.13,12.28 8.64,11.77 9.27,11.77M14.72,11.77C15.35,11.77 15.85,12.28 15.85,12.91C15.85,13.54 15.35,14.04 14.72,14.04C14.09,14.04 13.58,13.54 13.58,12.91A1.14,1.14 0 0,1 14.72,11.77Z"
                          />
                        </svg>
                      ),
                      title: "24/7 Support",
                      description:
                        "Reach out any time to get answers or troubleshoot issues. You don’t clock out at 5pm, and neither do we. ",
                    },
                  ],
                },
                {
                  title: "A920 Mobile Tablet",
                  img: a920_mobile_tablet,
                  description: (
                    <>
                      The A920 comes with a large high definition tablet color
                      display and a thermal printer that is neatly hidden so as
                      to maximize screen usage for customer facing transactions.
                      A wide range of advanced connectivity options are
                      supported, as well as built-in NFC contactless, electronic
                      signature capture and huge memory to enable exceptionally
                      fast processing of applications.
                    </>
                  ),
                  specs: [
                    ["Operating System", <>PayDroid based on Android 5.1</>],
                    [
                      "Processor",
                      <>
                        Application CPU: ARM Cortex A7 1.0GHz 4 Core Processor
                        Security CPU: Cortex-M4
                      </>,
                    ],
                    [
                      "Memory",
                      <>
                        1GB DDR3 SDRAM, 8GB EMMC | Expandable to 41GB via Micro
                        SD Slot
                      </>,
                    ],
                    [
                      "Display",
                      <>
                        5.0 Inch 720 x 1280 Pixel Capacitive Touch Screen |
                        White LED Backlights
                      </>,
                    ],
                    ["Physical Keys", <>1 x ON/OFF Key | 2 x Shortcuts</>],
                    [
                      "Printer",
                      <>
                        High-Speed Thermal Printer | Paper Roll Width /
                        Diameter: 2.25'' / 1.57''
                      </>,
                    ],
                    [
                      "Communications",
                      <>4G LTE | Bluetooth 4.0 | WiFi: 802.11 b/g/n, 2.4GHz</>,
                    ],
                    [
                      "Camera",
                      <>
                        5 Megapixel Auto Zoom Camera | 0.3 Megapixel Fixed Front
                        Camera
                      </>,
                    ],
                    [
                      "Card Readers",
                      <>
                        Magnetic Card Reader | Smart Card Reader | Contactless
                        Card Reader
                      </>,
                    ],
                    [
                      "Battery",
                      <>Long Lasting Li-Ion Battery, 5250mAH, 3.7V</>,
                    ],
                    [
                      "Audio",
                      <>
                        Speaker Functionality | Microphone & Sound Wave Payment
                        Supported
                      </>,
                    ],
                    [
                      "Physical",
                      <>
                        L x W x H (mm) : 175.7 x 78 x 57 Weight (g) : 458g
                        (Including Battery)
                      </>,
                    ],
                    ["Location", <>Supports GPS</>],
                    [
                      "Peripheral Ports",
                      <>
                        1 Micro-USB (OTG) | 1 SIM Card Slot | 2 PSAM Card Slot |
                        1 Micro SD Card Slot, Supports Up To 32 GB
                      </>,
                    ],
                    [
                      "Environmental",
                      <>
                        0°C ~ 50°C (32°F ~ 122°F) Operating Temperature <br />
                        5% ~ 95% Relative Humidity, Non-Condensing <br />
                        -20°C ~ 70°C (-4°F ~ 158°F) Storage Temperature <br />
                      </>,
                    ],
                    [
                      "Voltage",
                      <>
                        Input: 100 - 240V AC, 50Hz / 60Hz | Output: 5.0V DC,
                        2.0A
                      </>,
                    ],
                    [
                      "Accessories",
                      <>
                        Multi-Functional Base (optional) | Charging base
                        (optional) | 1.0m Micro-USB Charging Cable
                      </>,
                    ],
                    [
                      "Certifications",
                      <>
                        PCI PTS 5.x | EMV L1 & L2 | MasterCard Contactless |
                        Visa payWave | Amex ExpressPay | Discover D-PAS |
                        MasterCard TQM | NSICC
                      </>,
                    ],
                  ],
                  features: [
                    {
                      icon: (
                        <svg
                          style={{ width: 48, height: 48 }}
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M22,21H2V3H4V19H6V10H10V19H12V6H16V19H18V14H22V21Z"
                          />
                        </svg>
                      ),
                      title: "Accounting Sync",
                      description:
                        "Sync payments & transactions with QuickBooks (Online & Desktop). Eliminate the need for manual data entry.",
                    },
                    {
                      icon: (
                        <svg
                          style={{ width: 48, height: 48 }}
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M9,20A2,2 0 0,1 7,22A2,2 0 0,1 5,20A2,2 0 0,1 7,18A2,2 0 0,1 9,20M17,18A2,2 0 0,0 15,20A2,2 0 0,0 17,22A2,2 0 0,0 19,20A2,2 0 0,0 17,18M7.2,14.63C7.19,14.67 7.19,14.71 7.2,14.75A0.25,0.25 0 0,0 7.45,15H19V17H7A2,2 0 0,1 5,15C5,14.65 5.07,14.31 5.24,14L6.6,11.59L3,4H1V2H4.27L5.21,4H20A1,1 0 0,1 21,5C21,5.17 20.95,5.34 20.88,5.5L17.3,12C16.94,12.62 16.27,13 15.55,13H8.1L7.2,14.63M9,9.5H13V11.5L16,8.5L13,5.5V7.5H9V9.5Z"
                          />
                        </svg>
                      ),
                      title: "E-commerce",
                      description:
                        "Offer a simpler checkout experience. Increase your sales beyond brick-and-mortar.",
                    },
                    {
                      icon: (
                        <svg
                          style={{ width: 48, height: 48 }}
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M16,14H17.5V16.82L19.94,18.23L19.19,19.53L16,17.69V14M24,17A7,7 0 0,1 17,24C14.21,24 11.8,22.36 10.67,20H4C2.89,20 2,19.1 2,18V6C2,4.89 2.89,4 4,4H20A2,2 0 0,1 22,6V12.1C23.24,13.36 24,15.09 24,17M17,12A5,5 0 0,0 12,17A5,5 0 0,0 17,22A5,5 0 0,0 22,17A5,5 0 0,0 17,12M20,10V7H4V10H20Z"
                          />
                        </svg>
                      ),
                      title: "Virtual Terminal",
                      description:
                        "Accept phone orders and process card-not-present payments.",
                    },
                    {
                      icon: (
                        <svg
                          style={{ width: 48, height: 48 }}
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M19.5 3.5L18 2L16.5 3.5L15 2L13.5 3.5L12 2L10.5 3.5L9 2L7.5 3.5L6 2L4.5 3.5L3 2V22L4.5 20.5L6 22L7.5 20.5L9 22L10.5 20.5L12 22L13.5 20.5L15 22L16.5 20.5L18 22L19.5 20.5L21 22V2L19.5 3.5M19 19.09H5V4.91H19V19.09M6 15H18V17H6M6 11H18V13H6M6 7H18V9H6Z"
                          />
                        </svg>
                      ),
                      title: "Invoices",
                      description:
                        "Simplify your business income management. See all your incoming payments in one place.",
                    },
                    {
                      icon: (
                        <svg
                          style={{ width: 48, height: 48 }}
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M6,5.75L10.25,10H7V16H13.5L15.5,18H7A2,2 0 0,1 5,16V10H1.75L6,5.75M18,18.25L13.75,14H17V8H10.5L8.5,6H17A2,2 0 0,1 19,8V14H22.25L18,18.25Z"
                          />
                        </svg>
                      ),
                      title: "Recurring Payments",
                      description:
                        "Automate your billing. Set up subscriptions and other recurring bills",
                    },
                    {
                      icon: (
                        <svg
                          style={{ width: 48, height: 48 }}
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M21.41,11.58L12.41,2.58C12.05,2.22 11.55,2 11,2H4A2,2 0 0,0 2,4V11C2,11.55 2.22,12.05 2.59,12.42L11.59,21.42C11.95,21.78 12.45,22 13,22C13.55,22 14.05,21.78 14.41,21.41L21.41,14.41C21.78,14.05 22,13.55 22,13C22,12.45 21.77,11.94 21.41,11.58M5.5,7A1.5,1.5 0 0,1 4,5.5A1.5,1.5 0 0,1 5.5,4A1.5,1.5 0 0,1 7,5.5A1.5,1.5 0 0,1 5.5,7M17.27,15.27L13,19.54L8.73,15.27C8.28,14.81 8,14.19 8,13.5A2.5,2.5 0 0,1 10.5,11C11.19,11 11.82,11.28 12.27,11.74L13,12.46L13.73,11.73C14.18,11.28 14.81,11 15.5,11A2.5,2.5 0 0,1 18,13.5C18,14.19 17.72,14.82 17.27,15.27Z"
                          />
                        </svg>
                      ),
                      title: "Loyalty",
                      description:
                        "Award and track customer rewards. Keep customers coming back for repeat purchases through points and discounts.",
                    },
                    {
                      icon: (
                        <svg
                          style={{ width: 48, height: 48 }}
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M17,12V3A1,1 0 0,0 16,2H3A1,1 0 0,0 2,3V17L6,13H16A1,1 0 0,0 17,12M21,6H19V15H6V17A1,1 0 0,0 7,18H18L22,22V7A1,1 0 0,0 21,6Z"
                          />
                        </svg>
                      ),
                      title: "Messaging",
                      description:
                        "Communicate with your customers and get quick insights. Enjoy the convenience of an integrated messaging & feedback tool",
                    },
                    {
                      icon: (
                        <svg
                          style={{ width: 48, height: 48 }}
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M2,2H4V20H22V22H2V2M7,10H17V13H7V10M11,15H21V18H11V15M6,4H22V8H20V6H8V8H6V4Z"
                          />
                        </svg>
                      ),
                      title: "Advanced Register",
                      description:
                        "Keep track of all the items you sell. Expand the number of items you manage and track (up to 575)",
                    },
                  ],
                },
                {
                  title: "A80 Smart Desktop",
                  img: a80,
                  description:
                    "Versatile enough to work as a countertop or indoor portable payment terminal thanks to its optional battery backup. Always running at peak performance, full connectivity means the A80 is a reliable workhorse that will process payments safely and fast, even during the busiest hours.",
                  specs: [
                    ["OS", "Paydroid Powered by Android 6.0/7.1"],
                    ["Processor", "ARM Cortex A53"],
                    [
                      "Memory",
                      "1GB DDR + 8GB eMMC | 1 x Micro SD Card Slot, Supports up to 32GB",
                    ],
                    [
                      "Card Readers",
                      "Chip & PIN | NFC Contactless | Magnetic Stripe",
                    ],
                    ["Camera", "Optional 0.3MP front-facing"],
                    [
                      "Displays",
                      <>
                        4' TFT WVGA 480 x 800 Pixels
                        <br />
                        Multi-Point Capacitive Touch Screen
                      </>,
                    ],
                    [
                      "Communications",
                      <>
                        WiFi 2.4G/5G (optional) | 2G (GPRS) (optional) | Dial
                        (PSTN) | LAN
                        <br />
                        Bluetooth 4.0 (optional) | WCDMA (optional) | 4G
                        (FDD-LTE/TDD-LTE) (optional)
                      </>,
                    ],
                    ["Battery", "Optional 720mAh | 7.4V"],
                    [
                      "Printer",
                      "50 lines/sec | Paper roll outer diameter: 50mm",
                    ],
                    [
                      "SIM/SAM",
                      "3 x SAM | 1 x SIM + 2 x SAM | 2 x SIM + 1 x SAM",
                    ],
                    [
                      "Keys/Buttons",
                      "15 keys: 0~9 | Menu | Func | Cancel | Clear | Enter",
                    ],
                    ["Audio", "1 Speaker"],
                    [
                      "Ports",
                      "1 RS232 | 1 PSTN | 1 Ethernet | 1 PINpad | 1 Micro USB 2.0, OTG | 1 Host USB 2.0 | 1 Phone",
                    ],
                    [
                      "Adapter",
                      "Input: 100-240V AC, 50Hz/60Hz | Output: 9.0V DC, 1.0A",
                    ],
                    [
                      "Dimensions/Weight",
                      "181 * 84 * 81mm Weight: 405g (Excluding Battery)",
                    ],
                    [
                      "Environmental",
                      <>
                        -0°C ~ 50°C (32°F ~ 122°F) Operating Temperature
                        <br />
                        -20°C ~ 70°C (-4°F ~ 158°F) Storage Temperature
                        <br />
                        10% ~ 93% Relative Humidity, Non-Condensing
                      </>,
                    ],
                    [
                      "Accessories",
                      "Cluster Cable (optional) | Privacy PIN Shield (optional) | Battery Backup (optional)",
                    ],
                    [
                      "Certifications",
                      <>
                        PCI PTS 5.x SRED | EMV L1 & L2 | EMV Contactless L1 |
                        Visa payWave |
                        <br />
                        MasterCard Contactless | American ExpressPay | Discover
                        D-PAS | JCB J/Speedy |
                        <br />
                        Interac Flash L2 | IC MasterCard TQM | CE | RoHs | UL |
                        FCC
                      </>,
                    ],
                  ],
                  features: [
                    {
                      icon: (
                        <svg
                          style={{ width: 48, height: 48 }}
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M16.61 15.15C16.15 15.15 15.77 14.78 15.77 14.32S16.15 13.5 16.61 13.5H16.61C17.07 13.5 17.45 13.86 17.45 14.32C17.45 14.78 17.07 15.15 16.61 15.15M7.41 15.15C6.95 15.15 6.57 14.78 6.57 14.32C6.57 13.86 6.95 13.5 7.41 13.5H7.41C7.87 13.5 8.24 13.86 8.24 14.32C8.24 14.78 7.87 15.15 7.41 15.15M16.91 10.14L18.58 7.26C18.67 7.09 18.61 6.88 18.45 6.79C18.28 6.69 18.07 6.75 18 6.92L16.29 9.83C14.95 9.22 13.5 8.9 12 8.91C10.47 8.91 9 9.24 7.73 9.82L6.04 6.91C5.95 6.74 5.74 6.68 5.57 6.78C5.4 6.87 5.35 7.08 5.44 7.25L7.1 10.13C4.25 11.69 2.29 14.58 2 18H22C21.72 14.59 19.77 11.7 16.91 10.14H16.91Z"
                          />
                        </svg>
                      ),
                      title: "Powered by Android 6.0/7.1",
                    },
                    {
                      icon: (
                        <svg
                          style={{ width: 48, height: 48 }}
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M21,11C21,16.55 17.16,21.74 12,23C6.84,21.74 3,16.55 3,11V5L12,1L21,5V11M12,21C15.75,20 19,15.54 19,11.22V6.3L12,3.18L5,6.3V11.22C5,15.54 8.25,20 12,21M10,17L6,13L7.41,11.59L10,14.17L16.59,7.58L18,9"
                          />
                        </svg>
                      ),
                      title: "PCI 5 SRED",
                    },
                    {
                      icon: (
                        <svg
                          style={{ width: 48, height: 48 }}
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M7,1A2,2 0 0,0 5,3V21A2,2 0 0,0 7,23H17A2,2 0 0,0 19,21V3A2,2 0 0,0 17,1H7M7,4H17V20H7V4M9,6V10H10.5V7.5H13V6H9M13.5,14V16.5H11V18H15V14H13.5Z"
                          />
                        </svg>
                      ),
                      title: '4" Display',
                    },
                    {
                      icon: (
                        <svg
                          style={{ width: 48, height: 48 }}
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M13.73,15L9.83,21.76C10.53,21.91 11.25,22 12,22C14.4,22 16.6,21.15 18.32,19.75L14.66,13.4M2.46,15C3.38,17.92 5.61,20.26 8.45,21.34L12.12,15M8.54,12L4.64,5.25C3,7 2,9.39 2,12C2,12.68 2.07,13.35 2.2,14H9.69M21.8,10H14.31L14.6,10.5L19.36,18.75C21,16.97 22,14.6 22,12C22,11.31 21.93,10.64 21.8,10M21.54,9C20.62,6.07 18.39,3.74 15.55,2.66L11.88,9M9.4,10.5L14.17,2.24C13.47,2.09 12.75,2 12,2C9.6,2 7.4,2.84 5.68,4.25L9.34,10.6L9.4,10.5Z"
                          />
                        </svg>
                      ),
                      title: "0.3MP Front Camera",
                    },
                    {
                      icon: (
                        <svg
                          style={{ width: 48, height: 48 }}
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M12,10A2,2 0 0,1 14,12C14,12.5 13.82,12.94 13.53,13.29L16.7,22H14.57L12,14.93L9.43,22H7.3L10.47,13.29C10.18,12.94 10,12.5 10,12A2,2 0 0,1 12,10M12,8A4,4 0 0,0 8,12C8,12.5 8.1,13 8.28,13.46L7.4,15.86C6.53,14.81 6,13.47 6,12A6,6 0 0,1 12,6A6,6 0 0,1 18,12C18,13.47 17.47,14.81 16.6,15.86L15.72,13.46C15.9,13 16,12.5 16,12A4,4 0 0,0 12,8M12,4A8,8 0 0,0 4,12C4,14.36 5,16.5 6.64,17.94L5.92,19.94C3.54,18.11 2,15.23 2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12C22,15.23 20.46,18.11 18.08,19.94L17.36,17.94C19,16.5 20,14.36 20,12A8,8 0 0,0 12,4Z"
                          />
                        </svg>
                      ),
                      title: "Advanced Connectivity",
                    },
                  ],
                },
                {
                  title: "N910 Mobile Android POS",
                  img: n910_mobile_android_pos,
                  description:
                    "The state-of-the-art smart POS terminal N910 combines the functional flair of a smart tablet and the solidity of a payment terminal. Certified by top-level financial institutions, it provides various business solutions at all points of interaction between customers and merchants.",
                  specs: [
                    ["OS", <>Android 7.1</>],
                    [
                      "CPU",
                      <>
                        32-bit ARM Cortex A7 Quad-Core, 1.1GHz + Secure
                        processor
                      </>,
                    ],
                    [
                      "Memory",
                      <>
                        1GB Ram, 8GB FLASH (or) <br /> 2GB RAM, 16GB FLASH{" "}
                        <br /> MicroSD Card
                      </>,
                    ],
                    [
                      "Display",
                      <>
                        5-inch TFT full color LCD display, resolution of 1280 x
                        720 capacitive multi-touch screen, support electronic
                        signature
                      </>,
                    ],
                    [
                      "Wireless Communication",
                      <>
                        4G / 3G / 2G / Wi-Fi (801.11 b/g/n) / Bluetooth 2.1/4.1
                      </>,
                    ],
                    ["Positioning", <>GPS, GLONASS, BEIDOU</>],
                    [
                      "Magnetic Card Reader",
                      <>
                        Triple track, bi-directional, conforming to ISO
                        7810/7811/7813
                      </>,
                    ],
                    [
                      "Smart Card Reader",
                      <>
                        EMV L1/L2, conforming to ISO 7816, 1.8V/3V/5V,
                        synchronous and asynchronous, T=0, T=1
                      </>,
                    ],
                    [
                      "Contactless Card Reader",
                      <>
                        EMV Contactless L1, conforming to ISO 14443 Type A/B,
                        Mifare, Felica
                      </>,
                    ],
                    [
                      "Camera",
                      <>
                        0.3MP front camera, support 1D/2D code payment <br />
                        5MP autofocus rear camera with flashlight, support 1D/2D
                        code payment
                      </>,
                    ],
                    [
                      "Fingerprint Reader",
                      <>Fast recognized semiconductor fingerprint reader</>,
                    ],
                    [
                      "Audio",
                      <>
                        1x Speaker, 1x Microphone, support audio recording and
                        media play
                      </>,
                    ],
                    [
                      "Keypad",
                      <>
                        Secure virtual keypad for PIN entry, 1x power button, 1x
                        function key
                      </>,
                    ],
                    [
                      "Printer",
                      <>
                        High-speed thermal printer, 32lps, Paper roll diameter:
                        40mm, Paper width: 58mm
                      </>,
                    ],
                    ["Peripheral Ports", <>1x Micro USB (OTG), 1x DC Jack</>],
                    [
                      "Card slots",
                      <>
                        1x SIM, 1x SAM (or) <br />
                        1x SIM, 2x SAM
                      </>,
                    ],
                    [
                      "Battery",
                      <>
                        Li-ion battery, 7.2V/2600mAh/18.7Wh (Equal to
                        3.6V/5200mAh)
                      </>,
                    ],
                    [
                      "Power Supply",
                      <>
                        Input: 100-240V AC 50/60Hz, 0.3A <br />
                        Output: 5.0V DC, 2.0A
                      </>,
                    ],
                    [
                      "Docking Station",
                      <>
                        Charing base <br />
                        Multi-function base: Charging, Ehternet, Bluetooth,
                        Wi-Fi Access Point, 1x USB-A, 1x RS232
                      </>,
                    ],
                    ["Dimensions", <>190mm (L) x 81mm (W) x 54.9mm (H)</>],
                    ["Weight", <>440g including battery</>],
                    [
                      "Environmental",
                      <>
                        Operating Temperature: 0°C ~ 40°C (32°F ~ 103°F)
                        <br />
                        Storage Temperature: -20°C ~ 60°C (-4°F ~ 140°F)
                        <br />
                        Relative Humidity: 5% ~ 95% (Non-Condensing) <br />
                      </>,
                    ],
                    [
                      "Certifications",
                      <>Please contact us for latest certifications</>,
                    ],
                  ],
                  features: [],
                },
              ]}
            />
            <div className={styles.productsContainer}>
              <a className={baseStyles.heroCta} href={"/get-quote"}>
                Get Started
              </a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
